import { InfoCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Select, Space, Spin, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { Connector, SummaryData } from '../../redux/types/chargerTypes';
import totChar from '../../assets/ChargerImg/TotChar.png';
import session from '../../assets/ChargerImg/session.png';
import KWHc from '../../assets/ChargerImg/KWHConsumed.png';
import fault from '../../assets/ChargerImg/Fault.png';
import { fetchSummaryData } from '../../redux/services/chargerSevice';

const ChargerSummaryCard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [selectedFilter, setSelectedFilter] = React.useState('Today');
    const [fromDate, setFromDate] = React.useState(dayjs().startOf('day'));
    const [toDate, setToDate] = React.useState(dayjs().endOf('day'));
    const { detailedChargerDataForSite, loadingDetailedChargerDataForSite } = useSelector((state: RootState) => state.dashboard);
    const { summary: summaryDataFromResponse, isLoadingSummary: summaryDataLoading } = useSelector((state: RootState) => state.chargers);
    const chargerCount = detailedChargerDataForSite.all_connected_chargers.length;
    const { customerSites,
        selectedCustomerName,
        selectedSite } = useSelector((state: RootState) => state.userInfo);

    const Options = [
        { value: 'Today', label: 'Today' },
        { value: 'Yesterday', label: 'Yesterday' },
        { value: 'Last 7 Days', label: 'Last 7 Days' },
        { value: 'Last 30 Days', label: 'Last 30 Days' },
    ]

    const handleChange = (value: string) => {
        setSelectedFilter(value);
        switch (value) {
            case 'Today':
                setFromDate(dayjs().startOf('day'));
                setToDate(dayjs().endOf('day'));
                break;
            case 'Yesterday':
                setFromDate(dayjs().subtract(1, 'day').startOf('day'));
                setToDate(dayjs().subtract(1, 'day').endOf('day'));
                break;
            case 'Last 7 Days':
                setFromDate(dayjs().subtract(7, 'day').startOf('day'));
                setToDate(dayjs().endOf('day'));
                break;
            case 'Last 30 Days':
                setFromDate(dayjs().subtract(30, 'day').startOf('day'));
                setToDate(dayjs().endOf('day'));
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (fromDate && toDate && selectedCustomerName && selectedSite) {
            dispatch(
                fetchSummaryData({
                    selectedCustomerName,
                    avail_sites: customerSites,
                    selectedSite: selectedSite,
                    fromDate: fromDate.toISOString(), // Convert dayjs to ISO string
                    toDate: toDate.toISOString(),     // Convert dayjs to ISO string
                })
            );
        }
    }, [fromDate, toDate, selectedCustomerName, selectedSite, dispatch]);

    const ChargerCard = ({ title, tooltip, value, isLoading, img, colorText }: { title: string, tooltip: string, value: any, isLoading: boolean, img?: any, colorText?: string }) => {
        return (
            <Card className="card-custom">
                <Card.Grid hoverable={false} className="card-grid-custom" style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Left icon or image */}
                    <Space size="middle">
                        {img && <img src={img} alt="icon" />}
                        {/* add a divider vertical */}
                        <Divider type="vertical" style={{ height: '40px', backgroundColor: '#d9d9d9' }} />
                        <div>
                            {/* <Tooltip color="blue" title={tooltip}> */}
                            <Typography.Title level={1} style={{ margin: 0, color: colorText }}>
                                {isLoading ? <Spin spinning={isLoading} size="large" /> : <span>{value}</span>}
                            </Typography.Title>
                            {/* </Tooltip> */}
                            <Typography.Text type="secondary">{title}</Typography.Text>
                        </div>
                    </Space>
                    {/* Info icon on the far right */}
                    <Tooltip color="blue" title={tooltip}>
                        <InfoCircleOutlined style={{ marginLeft: 'auto', color: '#8c8c8c', marginBottom: 'auto', fontSize: '22px' }} />
                    </Tooltip>
                </Card.Grid>
            </Card>
        );
    };

    const calculateTotalEnergyConsumed = (data: SummaryData) => {
        let totalEnergyConsumed = 0;
        data && data.forEach(item => {
            item.session.forEach(session => {
                totalEnergyConsumed += session.metrics.energyConsumed || 0;
            });
        });
        return totalEnergyConsumed / 1000;
    };

    const getFaultsCount = (data: SummaryData) => {
        const checkData = data[0]?.connector
        return checkData?.filter(connector => connector.connectors[0].status === "Faulted")?.length || 0;
    }

    const getChargingSessions = (data: SummaryData) => {
        const checkData = data[0]?.session
        return checkData?.filter(session => session.status === "Ended").length;
    }

    return (
        // <Card title={selectDropDown}>
        <div>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }} className="subheading">Charger Summary for : &nbsp; <Select size='large' style={{ width: 150 }} options={Options} defaultValue={'Today'} placeholder="Select range" onChange={handleChange} /></div>
            <div className='h-18' />
            <Row justify="space-around" gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={loadingDetailedChargerDataForSite}
                        title="Total Chargers"
                        tooltip="Total Number of Chargers in the Site"
                        value={chargerCount}
                        img={totChar}
                        colorText='#1674EA'
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={summaryDataLoading}
                        title="KWH consumed"
                        tooltip={`Total KWH consumed ${selectedFilter}`}
                        value={calculateTotalEnergyConsumed(summaryDataFromResponse)}
                        img={KWHc}
                        colorText='#F3851F'
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={summaryDataLoading}
                        title={`Faults`}
                        tooltip={`Total number of faults encountered for ${selectedFilter}`}
                        value={getFaultsCount(summaryDataFromResponse)}
                        img={fault}
                        colorText='#F45D5D'
                    />
                </Col>
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                    <ChargerCard
                        isLoading={summaryDataLoading}
                        title="Charging sessions"
                        tooltip={`Number of Charging sessions Info for ${selectedFilter}`}
                        value={getChargingSessions(summaryDataFromResponse)}
                        img={session}
                        colorText='#38B34A'
                    />
                </Col>
            </Row>
        </div>
        // </Card>
    )
}

export default ChargerSummaryCard