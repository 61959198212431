import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { detailedChargerDataInterface, SessionData } from "../types/chargerTypes";
import dayjs from "dayjs";
import { FetchBPLiveDataPayload, detailedGeneratorData } from "../types/powerTypes";

interface FetchSessionsPayload2 {
    fromDate?: string;
    toDate?: string;
    selectedCustomerName: string;
    site_id?: number
}

const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl: any = protocol + url;

let cancelTokenSource11: CancelTokenSource | null = null;
let chargerPollingInterval: NodeJS.Timeout | null = null;

// Async thunk to fetch detailed summary charger data
export const fetchdetailedSummaryCharger = createAsyncThunk<
    detailedChargerDataInterface, // Return type
    FetchSessionsPayload2, // Argument type
    { rejectValue: string } // Optional reject value
>(
    'sites/fetchdetailedSummaryCharger',
    async (payload: FetchSessionsPayload2, thunkAPI) => {
        const { fromDate, toDate, selectedCustomerName } = payload;

        // If there's already an ongoing request, cancel it
        if (cancelTokenSource11) {
            cancelTokenSource11.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource11 = axios.CancelToken.source();

        const fromrange = fromDate ?? dayjs().subtract(7, 'day').toISOString();
        const torange = toDate ?? dayjs().toISOString();

        try {
            // API call
            const response = await axios.get<detailedChargerDataInterface>(
                `${baseUrl}charger/detailed_summary/${selectedCustomerName}/?from_date=${fromrange}&to_date=${torange}`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource11.token, // Use the new cancel token
                }
            );
            if (response.status === 200) {
                startPollingChargerSummary(payload, thunkAPI.dispatch);
            }
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled'); // Optional: you can return an empty array or specific error message
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

// Function to start polling
export const startPollingChargerSummary = (
    payload: FetchSessionsPayload2,
    dispatch: any,
    interval: number = 300000  // Default 3 minutes
) => {
    // Clear any existing polling
    stopPollingChargerSummary();

    // Start a new polling interval
    chargerPollingInterval = setInterval(() => {
        dispatch(fetchdetailedSummaryCharger(payload));
    }, interval);

    // Immediately fetch data before the first interval
    // dispatch(fetchdetailedSummaryCharger(payload));
};

// Function to stop polling
export const stopPollingChargerSummary = () => {
    if (chargerPollingInterval) {
        clearInterval(chargerPollingInterval);
        chargerPollingInterval = null;
    }
    if (cancelTokenSource11) {
        cancelTokenSource11.cancel('Request canceled');
        cancelTokenSource11 = null;
    }
};

let cancelTokenSource12: CancelTokenSource | null = null;

export const fetchdetailedSummaryChargerForSite = createAsyncThunk<
    detailedChargerDataInterface, // Return type (array of SessionData)
    FetchSessionsPayload2, // Argument type
    { rejectValue: string } // Optional reject value
>(
    'sites/fetchdetailedSummaryChargerForSite',
    async (payload: FetchSessionsPayload2, thunkAPI) => {
        const { fromDate, toDate, selectedCustomerName, site_id } = payload;

        // If there's already an ongoing request, cancel it
        if (cancelTokenSource12) {
            cancelTokenSource12.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource12 = axios.CancelToken.source();

        const fromrange = fromDate ?? dayjs().subtract(1, 'day').toISOString();
        const torange = toDate ?? dayjs().toISOString();

        try {
            // API call
            const { data } = await axios.get<detailedChargerDataInterface>(
                `${baseUrl}charger/detailed_summary/${selectedCustomerName}/?from_date=${fromrange}&to_date=${torange}&siteId=${site_id}`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource12.token, // Use the new cancel token
                }
            );

            return data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled'); // Optional: you can return an empty array or specific error message
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

let cancelTokenSource: CancelTokenSource | null = null;
let bpPollingInterval: NodeJS.Timeout | null = null;

// Define payload type
export type FetchBPLiveDataPayload2 = {
    selectedCustomerName: string;
    site_id?: number;
};

// Async thunk to fetch BP live data for customer
export const fetchBPLiveDataForCustomer = createAsyncThunk<
    detailedGeneratorData[], // Return type
    FetchBPLiveDataPayload2, // Argument type
    { rejectValue: string }  // Optional reject value
>(
    'sites/fetchBPLiveDataForCustomer',
    async (payload, thunkAPI) => {
        const { selectedCustomerName } = payload;

        // Cancel ongoing request if any
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Request canceled due to new request');
        }

        // Create a new cancel token for the current request
        cancelTokenSource = axios.CancelToken.source();

        try {
            const response = await axios.get<detailedGeneratorData[]>(
                `${baseUrl}trend/live_values_v3`,
                {
                    withCredentials: true,
                    headers: {
                        customer: selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource.token, // Use the new cancel token
                }
            );
            if (response.status === 200) {
                startPollingBPLiveData(payload, thunkAPI.dispatch);
            }
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', (error as AxiosError).message);
                return thunkAPI.rejectWithValue('Request canceled');
            }
            return thunkAPI.rejectWithValue((error as AxiosError).message);
        }
    }
);

// Function to start polling
export const startPollingBPLiveData = (
    payload: FetchBPLiveDataPayload2,
    dispatch: any,
    interval: number = 300000 // Default 5 minutes
) => {
    // Clear any existing polling
    stopPollingBPLiveData();

    // Start a new polling interval
    bpPollingInterval = setInterval(() => {
        dispatch(fetchBPLiveDataForCustomer(payload));
    }, interval);

    // Immediately fetch data before the first interval
    // dispatch(fetchBPLiveDataForCustomer(payload));
};

// Function to stop polling
export const stopPollingBPLiveData = () => {
    if (bpPollingInterval) {
        clearInterval(bpPollingInterval);
        bpPollingInterval = null;
    }
    if (cancelTokenSource) {
        cancelTokenSource.cancel('Request canceled');
        cancelTokenSource = null;
    }
};

let cancelTokenSource1: CancelTokenSource | null = null;

export const fetchBPLiveDataForCustomerForOneSite = createAsyncThunk<detailedGeneratorData[], FetchBPLiveDataPayload2, { rejectValue: string }>(
    'sites/fetchBPLiveDataForCustomerForOneSite',
    async (payload, thunkAPI) => {
        const { selectedCustomerName, site_id } = payload;

        if (cancelTokenSource1) {
            cancelTokenSource1.cancel('Request canceled');
        }

        cancelTokenSource1 = axios.CancelToken.source();

        try {
            const { data } = await axios.get<detailedGeneratorData[]>(
                `${baseUrl}trend/live_values_v3?siteId=${site_id}`,
                {
                    withCredentials: true,
                    headers: {
                        'customer': selectedCustomerName,
                    },
                    cancelToken: cancelTokenSource1.token,
                }
            );
            return data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);
