import React, { useState } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import * as moment from 'moment-timezone';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTimezoneAbb, setSelectedTimezone } from '../../redux/slices/customerSlice';
import { RootState } from '../../store';


dayjs.extend(timezone);

const { Option } = Select;

const TimezoneSelector = () => {
  const dispatch = useDispatch();
  const { selectedTimezone } = useSelector((state: RootState) => state.userInfo);
  // Retrieve the list of all time zones from moment-timezone
  const timezones = moment.tz.names().filter(tz =>
    ['US/Eastern', 'US/Central', 'US/Mountain', 'US/Pacific', 'UTC'].some(zone => tz.includes(zone)) &&
    tz !== 'Etc/UTC'
  )

  // Helper function to get the abbreviation for a timezone
  const getAbbreviation = (tz: string) => {
    const now = dayjs(); // Current time
    return now.tz(tz).format('z'); // Get the timezone abbreviation
  };

  // Handle selection change
  const handleChange = (value: string) => {
    dispatch(setSelectedTimezone(value));
    dispatch(setSelectedTimezoneAbb(getAbbreviation(value)));
  };

  return (
    <Select
      showSearch
      placeholder="Select a timezone"
      defaultValue={selectedTimezone}
      onChange={handleChange}
      // style={{ width: 300 }}
      style={{ width: 240 }}
      optionFilterProp="children"
    >
      {timezones.map((tz: any) => (
        <Option key={tz} value={tz}>
          {tz} ({getAbbreviation(tz)})
        </Option>
      ))}
    </Select>
  );
};

export default TimezoneSelector;
