import { APIProvider, Map, AdvancedMarker, Pin, InfoWindow } from "@vis.gl/react-google-maps";
import { Card, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import "./Maps.css";

interface MapProps {
    enableControls?: boolean;
    main?: boolean;
    loading?: boolean;
}

function MapsGlobal({ enableControls = true, main = true, loading = false }: MapProps) {
    const { customerSites } = useSelector((state: RootState) => state.userInfo);
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number } | null>(null);
    const [mapZoom, setMapZoom] = useState<number>(10);
    const [hoveredMarker, setHoveredMarker] = useState<number | null>(null);
    const [loadingMap, setLoadingMap] = useState(false);

    useEffect(() => {
        setLoadingMap(loading);
    }, [loading]);

    const locations = customerSites
        .map((data: any, index: number) => ({
            id: index,
            name: data?.name,
            latitude: data?.location?.latitude,
            longitude: data?.location?.longitude,
        }))
        .filter((location) => location.latitude !== undefined && location.longitude !== undefined);

    const MAP_API_KEY = "AIzaSyDQls-mu7AavtL0jNC1-NDLvAA8OhyL8MQ";
    const MAP_ID = "DEMO_MAP_ID"; // Replace with your Google Maps Map ID

    useEffect(() => {
        if (locations.length > 0) {
            const { latMin, latMax, lngMin, lngMax } = locations.reduce(
                (acc, loc) => ({
                    latMin: Math.min(acc.latMin, loc.latitude),
                    latMax: Math.max(acc.latMax, loc.latitude),
                    lngMin: Math.min(acc.lngMin, loc.longitude),
                    lngMax: Math.max(acc.lngMax, loc.longitude),
                }),
                { latMin: Infinity, latMax: -Infinity, lngMin: Infinity, lngMax: -Infinity }
            );

            const center = {
                lat: (latMin + latMax) / 2,
                lng: (lngMin + lngMax) / 2,
            };

            const calculatedZoom = getAdjustedZoom(latMin, latMax, lngMin, lngMax);

            if (!mapCenter || mapCenter.lat !== center.lat || mapCenter.lng !== center.lng) {
                setMapCenter(center);
            }
            if (mapZoom !== calculatedZoom) {
                setMapZoom(calculatedZoom);
            }
        }
    }, [locations]);

    const getAdjustedZoom = (latMin: number, latMax: number, lngMin: number, lngMax: number): number => {
        const latDiff = latMax - latMin;
        const lngDiff = lngMax - lngMin;
        const maxDiff = Math.max(latDiff, lngDiff);

        const zoom = 7 - Math.log10(maxDiff + 0.0001) * 2.5;
        return Math.min(Math.max(zoom, 3.5), 7);
    };

    if (locations.length === 0) {
        return (
            <Card className="commonFlexDisplay">
                <Typography.Paragraph
                    style={{
                        width: "100%",
                        height: "200px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                    }}
                >
                    No locations available.
                </Typography.Paragraph>
            </Card>
        );
    }

    return (
        <div className="gl-maps-container-global" style={{ height: main ? `calc(100vh - 64px)` : loadingMap ? "275px" : "275px" }}>
            {mapCenter && !loadingMap ? (
                <APIProvider apiKey={MAP_API_KEY}>
                    <Map
                        mapId={MAP_ID}
                        style={{
                            border: "1px solid #d4d4d4",
                            height: "100%",
                        }}
                        draggable={enableControls}
                        zoomControl={enableControls}
                        fullscreenControl={main}
                        streetViewControl={enableControls}
                        mapTypeControl={enableControls}
                        disableDefaultUI={!enableControls}
                        defaultCenter={mapCenter}
                        defaultZoom={mapZoom}
                        gestureHandling={"greedy"}
                        onClick={() => setHoveredMarker(null)}
                    >
                        {locations.map((loc, index) => (
                            <AdvancedMarker
                                key={loc.id}
                                position={{
                                    lat: loc.latitude,
                                    lng: loc.longitude,
                                }}
                                onClick={() => setHoveredMarker(index)}
                                title={loc.name || "Unnamed Location"}
                                clickable={true}

                            >
                                <Pin />
                                {hoveredMarker !== null && main && (
                                    <InfoWindow
                                        position={{
                                            lat: locations[hoveredMarker].latitude,
                                            lng: locations[hoveredMarker].longitude,
                                        }}
                                        onCloseClick={() => setHoveredMarker(null)}
                                    >
                                        <p style={{ padding: "5px", fontSize: "14px" }}>
                                            <strong>{locations[hoveredMarker].name || "Unnamed Location"}</strong>
                                        </p>
                                    </InfoWindow>
                                )}
                            </AdvancedMarker>
                        ))}
                    </Map>
                </APIProvider>
            ) : loadingMap ? (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                    }}
                >
                    <Spin spinning={loadingMap} size="large" />
                </div>
            ) : (
                <Typography.Paragraph
                    style={{
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                    }}
                >
                    Location unknown
                </Typography.Paragraph>
            )}
        </div>
    );
}

export default MapsGlobal;
