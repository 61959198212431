import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { Spin, Row, Col, Typography, Alert } from 'antd'
import './AppContent.css';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchAllCustomerSites, fetchAllPermittedCustomers, fetchGeneralDetails, fetchUserInfo } from "../../redux/services/customerService";
import { clearUserInfo, clearWeatherInfo, setSelectedCustomerName, setSelectedSite, setSelectedTimezone, setSelectedTimezoneAbb } from "../../redux/slices/customerSlice";
import _, { set } from "lodash";
import Logout from "./LogOut";
import { clearPowerState } from "../../redux/slices/powerSlice";
import { clearChargerData } from "../../redux/slices/chargerSlice";
import { GENERAL, INTRODUCTION } from "../../utils/constants";
import { clearDashboardData } from "../../redux/slices/dashboardSlice";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
var moment = require('moment-timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

export default function AppContent() {
    const navigate = useNavigate();
    const location = useLocation();
    const [authorizationHandled, setAuthorizationHandled] = useState<boolean>(false);
    const [loginStatus, setLoginStatus] = useState<string>('');
    const [authLoading, setAuthLoading] = useState<boolean>(true);
    const dispatch = useDispatch<AppDispatch>();
    const { permittedCustomers,
        permittedCustomersLoading,
        permittedCustomersError,
        customerSites,
        selectedCustomerName,
        selectedTimezone,
        loadingLogout,
        selectedSite,
        userInfo,
        loading,
        customerSitesLoading,
        customerSitesError } = useSelector((state: RootState) => state.userInfo);

    const { customer, site }: any = useParams();

    useEffect(() => {
        if (customer === selectedCustomerName && selectedCustomerName) {
            setLoginStatus('success');
        }
    }, [selectedCustomerName, customer])

    useEffect(() => {
        if (selectedSite)
            dispatch(fetchGeneralDetails({ selectedCustomerName, avail_sites: customerSites, selectedSite }))
    }, [selectedSite])

    const clearCookie = () => {
        axios
            .post(`${baseUrl}microapi/logout-cleanup/`, {}, {
                withCredentials: true,
            })
            .then((response) => {
                // Handle the response as needed
                initiateLogin();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const callUserApi = () => {
        setAuthLoading(false);
        dispatch(fetchUserInfo());
        _.isEmpty(permittedCustomers) && dispatch(fetchAllPermittedCustomers());
    }

    useEffect(() => {
        const code = new URLSearchParams(location.search).get('code');
        if (localStorage.getItem('user') === 'true') {
            // navigate('/home');
            callUserApi();
        } else if (code && !authorizationHandled) {
            setAuthorizationHandled(true);
            handleAuthorizationCode(code);
        } else {
            clearCookie();
        }
    }, [location.search]);


    const protocol: any = process.env.REACT_APP_PROTOCOL;
    const url: any = process.env.REACT_APP_API_URL;
    const baseUrl = protocol + url;
    // const reactDashboardUrl = process.env.REACT_APP_URL;

    const initiateLogin = () => {
        const authUrl: any = process.env.REACT_APP_AUTH_URL;
        // Redirect the user to the OAuth2 authorization URL
        window.location.href = authUrl;
    };

    const handleAuthorizationCode = (code: string) => {
        setAuthLoading(true);
        // Create FormData object
        const formData = new FormData();

        // Append the authorization code to the FormData
        formData.append("code", code);

        // Perform the axios request
        axios
            .post(`${baseUrl}oauth/auth0/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
                }, withCredentials: true,
            })
            .then((response) => {
                // Handle the response as needed
                // Now that the authentication is successful, switch to sending cookies
                axios.defaults.withCredentials = true;

                localStorage.setItem('user', 'true');
                setLoginStatus('success');
                // navigate("/Home");
                navigate("/", { replace: true });
                // callUserApi();
            })
            .catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    clearCookie();
                }
                setLoginStatus('error');
                if (error.response.status === 400) {
                    navigate("/errorLogin");
                }
                // initiateLogin();
            }).finally(() => {
                setAuthLoading(false);
            });
    };

    useEffect(() => {
        if (customer && !_.isEmpty(permittedCustomers) && _.isEmpty(site)) {
            if (permittedCustomers.find(data => data.customer === customer)) {
                dispatch(setSelectedCustomerName(customer));
                dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
            }
            else {
                dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
                navigate(`/${permittedCustomers[0]?.customer}`)
            }
        }
        else if (_.isEmpty(customer) && _.isEmpty(site) && !_.isEmpty(permittedCustomers)) {
            dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
            dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
            navigate(`/${permittedCustomers[0]?.customer}`)
        }
    }, [customer, permittedCustomers])

    useEffect(() => {
        if (selectedCustomerName && _.isEmpty(site) && !_.isEmpty(customer) && customer !== selectedCustomerName)
            navigate(`/${selectedCustomerName}`)
    }, [selectedCustomerName])

    const getAbbreviation = (tz: string) => {
        if (!tz) return 'PST'; // Default fallback if no timezone provided

        // Convert current time to the given timezone using moment-timezone
        const nowMoment = moment().tz(tz);

        // Fetch timezone abbreviation using 'z'
        const abbreviation = nowMoment.format('z');

        // If abbreviation fails (if it returns 'z'), return the offset instead
        return abbreviation !== 'z' ? abbreviation : nowMoment.format('ZZ');
    };

    useEffect(() => {
        if (selectedTimezone) {
            const abbreviation = getAbbreviation(selectedTimezone);
            dispatch(setSelectedTimezoneAbb(abbreviation || 'PST'));
        }
    }, [selectedTimezone, dispatch]);

    useEffect(() => {
        if (!permittedCustomersLoading && !_.isEmpty(permittedCustomers) && !_.isEmpty(customer) && !_.isEmpty(site)) {
            const permittedCustomerExists = permittedCustomers.find(data => data.customer === customer);
            if (_.isEmpty(permittedCustomerExists) && !_.isEmpty(customer)) {
                window.location.href = `/`;
            }
            else {
                const permittedSiteExists = customerSites.find(data => data.name === site);
                if (customer && site && customer && !permittedSiteExists) {
                    const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);
                    if (!_.isEmpty(selectedCustomerObj)) {
                        dispatch(fetchAllCustomerSites(customer)).then((response: any) => {
                            // Assuming the response is an array of sites
                            if (response?.payload.length === 0) {
                                dispatch(setSelectedCustomerName(customer));
                                dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                                dispatch(setSelectedSite(''));
                                navigate(`/${customer}`);
                                return;
                            }
                            const siteExists = response?.payload.find((siteData: any) => siteData.name === site);

                            if (siteExists) {
                                dispatch(setSelectedCustomerName(customer));
                                dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                                dispatch(setSelectedSite(site));
                                // Perform additional actions with siteExists if needed
                            } else {
                                dispatch(fetchAllCustomerSites(permittedCustomers[0]?.customer)).then((response: any) => {
                                    if (response?.payload.length === 0) {
                                        dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                        dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
                                        dispatch(setSelectedSite(''));
                                        navigate(`/${permittedCustomers[0]?.customer}`);
                                        return;
                                    }
                                    const selectSiteFromResponse = response?.payload[0]?.name;
                                    dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                    dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
                                    dispatch(setSelectedSite(selectSiteFromResponse));
                                    // userInfo?.show_intro ? navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/${INTRODUCTION}`) : navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                                    navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                                })
                            }
                        });
                    }
                } else {
                    dispatch(fetchAllCustomerSites(permittedCustomers[0]?.customer)).then((response: any) => {
                        if (response?.payload.length === 0) {
                            dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                            dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
                            dispatch(setSelectedSite(''));
                            navigate(`/${permittedCustomers[0]?.customer}`);
                            return;
                        }
                        const selectSiteFromResponse = response?.payload[0]?.name;
                        dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                        dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
                        dispatch(setSelectedSite(selectSiteFromResponse));
                        // userInfo?.show_intro ? navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/${INTRODUCTION}`) : navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                        navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                    })
                }
            }
        }
    }, [permittedCustomersLoading, permittedCustomers, userInfo]);

    useEffect(() => {
        if (!_.isEmpty(customer) && !_.isEmpty(site) && customer !== selectedCustomerName && site !== selectedSite && !_.isEmpty(selectedCustomerName) && !_.isEmpty(selectedSite)) {
            if (customer !== selectedCustomerName) {
                dispatch(clearUserInfo());
                dispatch(clearWeatherInfo());
                dispatch(clearPowerState());
                dispatch(clearChargerData());
                const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);
                if (selectedCustomerObj) {
                    dispatch(setSelectedCustomerName(customer));
                    dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                    dispatch(fetchAllCustomerSites(customer)).then((response: any) => {
                        if (response?.payload.length === 0) {
                            dispatch(setSelectedCustomerName(customer));
                            dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                            dispatch(setSelectedSite(''));
                            navigate(`/${customer}`);
                            return;
                        }
                        // Assuming the response is an array of sites
                        const siteExists = response?.payload.find((siteData: any) => siteData.name === site);

                        if (siteExists) {
                            dispatch(setSelectedCustomerName(customer));
                            dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                            dispatch(setSelectedSite(site));
                            // Perform additional actions with siteExists if needed
                        } else {
                            dispatch(fetchAllCustomerSites(permittedCustomers[0]?.customer)).then((response: any) => {
                                if (response?.payload.length === 0) {
                                    dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                    dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
                                    dispatch(setSelectedSite(''));
                                    navigate(`/${permittedCustomers[0]?.customer}`);
                                    return;
                                }
                                const selectSiteFromResponse = response?.payload[0]?.name;
                                dispatch(setSelectedCustomerName(permittedCustomers[0]?.customer));
                                dispatch(setSelectedTimezone(permittedCustomers[0]?.preferences?.timezone || 'US/Pacific'));
                                dispatch(setSelectedSite(selectSiteFromResponse));
                                // userInfo?.show_intro ? navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/${INTRODUCTION}`) : navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                                navigate(`/${permittedCustomers[0]?.customer}/${selectSiteFromResponse}/`);
                            })
                        }
                    })
                }
            }
            else if (site !== selectedSite) {
                const siteExists = customerSites.find((siteData: any) => siteData.name === site);
                if (siteExists) {
                    dispatch(setSelectedSite(site));
                }
            }
        }
        else if ((_.isEmpty(site) && !_.isEmpty(customer) && customer !== selectedCustomerName) || (!_.isEmpty(customer) && customer !== selectedCustomerName) || (!_.isEmpty(customer) && customer !== selectedCustomerName && _.isEmpty(selectedSite))) {

            // Clear relevant data
            dispatch(clearUserInfo());
            dispatch(clearPowerState());
            dispatch(clearChargerData());
            dispatch(clearDashboardData());

            // Update customer and site if conditions are met
            if (!_.isEmpty(customer) && !_.isEmpty(site) && permittedCustomers?.length > 0) {
                const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);

                if (selectedCustomerObj) {
                    dispatch(setSelectedCustomerName(customer));
                    dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
                    dispatch(fetchAllCustomerSites(customer)).then((response: any) => {
                        const availableSites = response?.payload || [];

                        if (availableSites.length > 0) {
                            // Check if the current `site` is in the available sites
                            const matchingSite = availableSites.find((siteData: any) => siteData.name === site);

                            if (matchingSite) {
                                dispatch(setSelectedSite(site));
                            } else {
                                // Set the first site if no match found
                                dispatch(setSelectedSite(availableSites[0].name));
                            }
                        }
                    });
                }
            }
        }
    }, [customer, site, userInfo])

    useEffect(() => {
        if (!_.isEmpty(customer) && _.isEmpty(site)) {
            if (permittedCustomers?.length > 0) {
                dispatch(setSelectedCustomerName(customer));
                dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific'));
            }
        }
    }, [customer, permittedCustomers])

    return (
        <div className="AppContent scrollable">
            <div>
                {/* Loading Screen */}
                {(permittedCustomersLoading && permittedCustomersError.length === 0 || authLoading) && (
                    <Spin size="large" style={{ backgroundColor: 'transparent' }}>
                        <Row justify="center">
                            <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                                <Typography.Title level={2} type="secondary">
                                    Loading. Please wait!
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Spin>
                )}

                {/* Error Alert for Network Issues or Permission Issues */}
                {!loadingLogout && loginStatus !== 'error' && permittedCustomersError?.length > 0 && (
                    <div className="centerContainer">
                        <Alert
                            message={
                                ["Network Error", "Request failed with status code 500"].includes(permittedCustomersError)
                                    ? permittedCustomersError
                                    : "Contact Admin"
                            }
                            description={
                                ["Network Error", "Request failed with status code 500"].includes(permittedCustomersError)
                                    ? "Unable to reach the server"
                                    : "No permission has been added to your account. Please contact e-Boost admin."
                            }
                            type={
                                ["Network Error", "Request failed with status code 500"].includes(permittedCustomersError)
                                    ? "error"
                                    : "warning"
                            }
                            showIcon
                            style={{ width: '100%', fontSize: '20px' }}
                            action={<Logout />}
                        />
                    </div>
                )}

                {/* Alert for No Customers Found */}
                {!loadingLogout && !authLoading && loginStatus !== 'error' && !permittedCustomersLoading && permittedCustomers.length === 0 && permittedCustomersError?.length === 0 && (
                    <div className="centerContainer">
                        <Alert
                            message="No customers found!"
                            description="Please contact your administrator."
                            type="error"
                            showIcon
                            style={{ width: '100%', fontSize: '20px' }}
                            action={<Logout />}
                        />
                    </div>
                )}

                {/* Main Content or Outlet */}
                {(loadingLogout === false && loginStatus === 'success' || customerSitesLoading) && <Outlet />}

                {/* Logout Loading Screen */}
                {loadingLogout && (
                    <Spin size="large">
                        <Row justify="center">
                            <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                                <Typography.Title level={2} type="secondary">
                                    Logging you Out. Please wait!
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Spin>
                )}
            </div>
        </div>

    );
}
