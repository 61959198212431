import { AdvancedMarker, APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { Card, Col, Empty, Row, Spin, Typography } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./Maps.css";
import { RootState } from "../../store";
import { MAP_ZOOM_LEVEL, TIMEZONES } from "../../utils/constants";
import dayjs from "dayjs";

interface MapProps {
    cardHeight?: any;
    enableControls?: boolean;
    main?: boolean;
}

function Maps({ cardHeight, enableControls = true, main = true }: MapProps) {
    const { customerSites, selectedSite } = useSelector((state: RootState) => state.userInfo);
    const { selectedTimezone, selectedTimezoneAbb } = useSelector((state: RootState) => state.userInfo);
    const siteLocation = customerSites.find(site => site.name === selectedSite)?.location;
    const [selectedSiteLocation, setSelectedSiteLocation] = useState<string>("");
    const [selectedSiteLocationUpdated, setSelectedSiteLocationUpdated] = useState("");
    const [position, setPosition] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const MAP_API_KEY = "AIzaSyDQls-mu7AavtL0jNC1-NDLvAA8OhyL8MQ";

    useEffect(() => {
        if (siteLocation && siteLocation?.latitude > 0) {
            const latitude = siteLocation.latitude;
            const longitude = siteLocation.longitude;
            setPosition({ lat: latitude, lng: longitude });
            setSelectedSiteLocationUpdated(siteLocation.last_update_at);
        }
        else if (!siteLocation) {
            setPosition(null);
            setSelectedSiteLocationUpdated("");
        }
    }, [siteLocation]);

    const prevPosition = useRef<{ lat: string | number; lng: string | number } | null>(null);

    useEffect(() => {
        const longitude = _.get(position, "lng", "");
        const latitude = _.get(position, "lat", "");

        const isLongitudeEmpty = _.isEmpty(`${longitude}`);
        const isLatitudeEmpty = _.isEmpty(`${latitude}`);

        // Check if position has changed before triggering fetch
        if (
            !isLongitudeEmpty &&
            !isLatitudeEmpty &&
            (!prevPosition.current ||
                prevPosition.current.lat !== latitude ||
                prevPosition.current.lng !== longitude)
        ) {
            try {
                const fetchUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${MAP_API_KEY}`;
                setLoading(true);

                fetch(fetchUrl)
                    .then(async (res) => {
                        const data = await res.json();
                        const formattedAddress = _.get(data, "results.0.formatted_address", "");
                        if (formattedAddress) {
                            setSelectedSiteLocation(formattedAddress);
                        }
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.log("🚀 ~ fetch ~ err:", err);
                        setLoading(false);
                    });
            } catch (error) {
                console.log("🚀 ~ useEffect ~ error:", error);
            }

            // Update the previous position
            prevPosition.current = { lat: latitude, lng: longitude };
        }
    }, [position]);

    if (!position) {
        return (
            <Card>
                <Empty description="No location set for this site."></Empty>
            </Card>
        );
    }

    const responsiveCol = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };

    return (
        <Card
            className="gl-maps-container "
            size="small"
            bordered={false}
            styles={{ header: { borderColor: enableControls ? 'transparent' : '#d4d4d4' } }}
            style={cardHeight ? { height: cardHeight } : {}}
            title={<div className="subheading">
                {main && <div>{selectedSiteLocation}</div>}
                {!main && <div>{selectedSiteLocation}</div>}
            </div>}
            extra={<div>
                {main && selectedSiteLocationUpdated && <div style={{ textAlign: "right", color: '#888' }}>Last updated: {selectedTimezoneAbb === 'UTC' ? dayjs.utc(selectedSiteLocationUpdated).format('YYYY-MM-DD HH:mm:ss') : dayjs(selectedSiteLocationUpdated).tz(selectedTimezone).format('YYYY-MM-DD HH:mm:ss')} {selectedTimezoneAbb} </div>}
                {main && !selectedSiteLocationUpdated && <div style={{ textAlign: "right", color: '#888' }}>GPS OFF</div>}
            </div>}
        >
            {position ? (
                <>
                    {loading ? <Spin size="large">
                        <Row justify="center">
                            <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                                <Typography.Title level={2} type="secondary">Loading. Please wait!</Typography.Title>
                            </Col>
                        </Row>
                    </Spin>
                        : <APIProvider apiKey={MAP_API_KEY}>
                            <Map
                                mapId={'DEMO_MAP_ID'}
                                clickableIcons={enableControls}
                                style={{
                                    border: "1px solid #d4d4d4",
                                    height: cardHeight === undefined ? "84vh" : cardHeight,
                                }}
                                draggable={enableControls}
                                zoomControl={enableControls}        // Disable zoom control
                                fullscreenControl={main}  // Disable fullscreen control
                                streetViewControl={enableControls}  // Disable street view control
                                mapTypeControl={enableControls}     // Disable map type control (like satellite view)
                                disableDefaultUI={enableControls}
                                // fullscreenControl={main}
                                defaultCenter={position}
                                defaultZoom={MAP_ZOOM_LEVEL}
                                gestureHandling={"greedy"}
                            >
                                <AdvancedMarker position={position} />
                            </Map>
                        </APIProvider>}
                </>
            ) : (
                <Typography.Paragraph
                    style={{
                        width: "100%",
                        height: cardHeight === undefined ? "90vh" : cardHeight,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        fontSize: "20px",
                    }}
                >
                    Location unknown
                </Typography.Paragraph>
            )}
        </Card>
    );
}

export default Maps;
