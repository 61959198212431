import { Card, Col, Row, Typography } from "antd";
import classNames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";
import NoChargerImg from "../../assets/No_Charger_Img.jpg";
import OrangeCircle from "../../assets/orange-circle.png";
import RedCircle from "../../assets/red-circle.png";
import GreenCircle from "../../assets/transparent-green-circle.png";
import { RootState } from "../../store";
import { getBatteryStatus } from "../../utils/common";
import { useNavigate } from "react-router-dom";




const ChargerList = () => {

    const { detailedChargerDataForSite } = useSelector((state: RootState) => state.dashboard);

    const { customerSites, selectedSite } = useSelector((state: RootState) => state.userInfo);

    const navigate = useNavigate();

    return (
        <div>
            <Row>
                <Col className="textTypoMain">All Available Chargers</Col>
            </Row>
            <div className="h-18" />
            <Row gutter={[16, 16]}>
                {detailedChargerDataForSite.all_connected_chargers.map((data) => {
                    const chargerId = _.get(data, "id", '');
                    const chargerStatuses = detailedChargerDataForSite.connector.filter(item => item.charger === chargerId)?.[0];
                    const chargerCurrentStatus = _.get(chargerStatuses, "connectors[0].status", "Unavailable");
                    let batteryStatus = getBatteryStatus(chargerCurrentStatus);
                    const staticOrNot = data && data.api_source && data.api_source === 'static';
                    // const xStatic = customerSites.filter(site => site.name === selectedSite)?.[0]?.preferences?.charger?.datasource !== 'API';
                    staticOrNot ? batteryStatus = 'static' : batteryStatus = batteryStatus
                    const isStatusChargingClassName = classNames({
                        "grey-img": batteryStatus !== 'charging',
                    });

                    const isStatusFaultedClassName = classNames({
                        "grey-img": batteryStatus !== 'faulted',
                    });

                    const isStatusAvailableClassName = classNames({
                        "grey-img": batteryStatus !== 'available',
                    });

                    const handleImageError = (event: any) => {
                        event.target.src = NoChargerImg;
                    };

                    return (
                        <Col key={data.id} xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Card
                                hoverable
                                style={{ height: "234px", flex: 1, overflowY: 'auto' }}
                                // title={data.charger_title}
                                size="small"
                                onClick={() => navigate(`${chargerId}`)}
                                bordered={false}
                            >
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Row>
                                            <Col span={24}>
                                                <div className="img-container">
                                                    <img
                                                        src={data.charger_model.image_src}
                                                        alt="Charger Vendor"
                                                        onError={handleImageError}
                                                        className="charger-image"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Typography.Text className="textTypo">
                                            {data.charger_title}
                                        </Typography.Text>
                                        <div className="h-18" />
                                        <div>
                                            <div className="headerRunning">Running Status</div>
                                            <div className="h-12" />
                                            <div className="card-row">
                                                <img
                                                    src={batteryStatus === "available" ? GreenCircle : OrangeCircle}
                                                    className={isStatusAvailableClassName}
                                                    alt="Available"
                                                    width={15}
                                                    height={15}
                                                />
                                                <span className="status-name">Available</span>
                                            </div>
                                            <div className="card-row">
                                                <img
                                                    src={OrangeCircle}
                                                    className={isStatusChargingClassName}
                                                    alt="Charging"
                                                    width={15}
                                                    height={15}
                                                />
                                                <span className="status-name">Charging</span>
                                            </div>
                                            <div className="card-row">
                                                <img
                                                    src={batteryStatus === "faulted" ? RedCircle : OrangeCircle}
                                                    className={isStatusFaultedClassName}
                                                    alt="Faulted"
                                                    width={15}
                                                    height={15}
                                                />
                                                <span className="status-name">Faulted</span>
                                            </div>
                                            {data.charger_model.model === "HCS-60" && (
                                                <>
                                                    <Row className="power-status-row">
                                                        <Col span={3} className="circleStyle"></Col>
                                                        <Col span={21}>&nbsp;&nbsp;&nbsp;100% Power</Col>
                                                    </Row>
                                                    <Row className="power-status-row">
                                                        <Col span={3} className="circleStyle"></Col>
                                                        <Col span={21}>&nbsp;&nbsp;&nbsp;50% Power</Col>
                                                    </Row>
                                                    <Row className="power-status-row">
                                                        <Col span={3} className="circleStyle"></Col>
                                                        <Col span={21}>&nbsp;&nbsp;&nbsp;25% Power</Col>
                                                    </Row>
                                                    <Row className="power-status-row">
                                                        <Col span={3} className="circleStyle"></Col>
                                                        <Col span={21}>&nbsp;&nbsp;&nbsp;Standby</Col>
                                                    </Row>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </div>
    )
}

export default ChargerList