import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { Spin, Row, Col, Typography } from 'antd';
import { AppDispatch, RootState } from './store';
import { clearUserInfo, setSelectedCustomerName, setSelectedSite, setSelectedTimezone } from './redux/slices/customerSlice';
import { clearPowerState, setSelectedFuelId, setSelectedGeneratorId } from './redux/slices/powerSlice';
import { clearChargerData } from './redux/slices/chargerSlice';
import { clearDashboardData } from './redux/slices/dashboardSlice';
import { fetchAllCustomerSites } from './redux/services/customerService';


type ProtectedRouteProps = {
    route: string;
    children: React.ReactNode;
};

const ProtectedRoute = ({ route, children }: ProtectedRouteProps) => {
    // Redux selectors for state management
    const {
        permittedCustomers,
        permittedCustomersLoading,
        customerSites,
        selectedCustomerName,
        selectedSite,
        userInfo,
        customerSitesLoading,
    } = useSelector((state: RootState) => state.userInfo);

    const { customer, site, powerId, fuelId }: any = useParams(); // Extract route params
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    /**
     * Helper function to clear all relevant state data.
     */
    const clearAllData = () => {
        dispatch(clearUserInfo());
        dispatch(clearPowerState());
        dispatch(clearChargerData());
        dispatch(clearDashboardData());
    };

    /**
     * Helper function to set the selected customer, timezone, and fetch its sites.
     * @param customer The customer name to set and fetch sites for.
     */
    const setCustomerAndFetchSites = async (customer: string) => {
        const timezone =
            permittedCustomers.find(data => data.customer === customer)?.preferences?.timezone || 'US/Pacific';
        dispatch(setSelectedCustomerName(customer));
        dispatch(setSelectedTimezone(timezone));
        return await dispatch(fetchAllCustomerSites(customer));
    };

    /**
     * Main logic to handle customer or site changes and fetch data accordingly.
     */
    const handleCustomerChange = async () => {
        if (!customer) return; // If no customer in URL, exit

        clearAllData(); // Clear stale data

        const selectedCustomerObj = permittedCustomers.find(data => data.customer === customer);
        if (!selectedCustomerObj) return; // If customer doesn't exist, exit

        const response: any = await setCustomerAndFetchSites(customer); // Fetch customer sites
        const availableSites = response?.payload || []; // Get site data

        // Redirect to customer-level page if no sites are available
        if (availableSites.length === 0) {
            navigate(`/${customer}`);
            return;
        }

        // Check if the current site in URL matches an available site
        const matchingSite = availableSites.find((siteData: any) => siteData.name === site);
        if (matchingSite) {
            dispatch(setSelectedSite(site));
        } else {
            // Default to the first site if no match
            dispatch(setSelectedSite(availableSites[0].name));
            navigate(`/${customer}/${availableSites[0].name}/`);
        }
    };

    /**
     * useEffect to handle changes to customer or site in the URL.
     */
    useEffect(() => {
        if (customer !== selectedCustomerName || (site !== selectedSite && selectedSite.length > 0)) {
            handleCustomerChange();
        }
    }, [customer, site]);

    // Memoized selected customer object for efficiency
    const selectedCustomerObj = useMemo(() =>
        permittedCustomers.find(data => data.customer === customer),
        [permittedCustomers, customer]
    );

    // useEffect(() => {
    //     if (!selectedSite && customerSites.length) {
    //         let x = customerSites.filter(item => item.name === site)
    //         if (x.length > 0) {
    //             dispatch(setSelectedSite(x[0].name));
    //             fuelId && dispatch(setSelectedFuelId(Number(fuelId)));
    //             powerId && dispatch(setSelectedGeneratorId(Number(powerId)));
    //         }
    //     }
    // }, [site, customerSites]);

    /**
     * Loading state handling for sites or customers.
     */
    if (customerSitesLoading || permittedCustomersLoading || !selectedSite) {
        return (
            <Spin size="large" style={{ backgroundColor: 'transparent' }}>
                <Row justify="center">
                    <Col xs={24} sm={22} md={20} lg={18} xl={16} className="loadingCol">
                        <Typography.Title level={2} type="secondary">
                            Loading. Please wait!
                        </Typography.Title>
                    </Col>
                </Row>
            </Spin>
        );
    }

    // Redirect if customer doesn't exist
    if (!selectedCustomerObj) {
        return <Navigate to="/403" replace />;
    }

    // Find the selected site data
    const selectedSiteData = customerSites.find((site) => site.name === selectedSite);
    const presentComponents: any = selectedSiteData?.preferences?.present_components || [];

    /**
     * Final access check based on the route and available components.
     */
    if (presentComponents.includes(route)) {
        return <>{children}</>; // Render children if access is granted
    }

    return <Navigate to="/403" replace />; // Redirect if access is denied
};

export default ProtectedRoute;
