import { BellOutlined, EnvironmentOutlined, MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Drawer, Dropdown, Layout, Menu, Row, Select, Skeleton, Spin, theme, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import LogoSmall from "../../assets/logo/logoSmall.png";
import LogoDark from "../../assets/logo/LogoDarkV2.svg";
import UserLogo from '../../assets/Icons/user.226x256.png';
import Alarms from '../../assets/sideNav/Alarms.svg';
import AlarmsGreen from '../../assets/sideNav/selected/Alarms.svg';
import Cameras from '../../assets/sideNav/Camera.svg';
import CamerasGreen from '../../assets/sideNav/selected/Camera.svg';
import Docs from '../../assets/sideNav/Docs.svg';
import DocsGreen from '../../assets/sideNav/selected/Docs.svg';
import PowerSVG from '../../assets/sideNav/Power.svg';
import PowerGreen from '../../assets/sideNav/selected/Power.svg';
import Charger from '../../assets/sideNav/Charger.svg';
import ChargerGreen from '../../assets/sideNav/selected/Charger.svg';
import Fuel from '../../assets/sideNav/Fuel.svg';
import FuelGreen from '../../assets/sideNav/selected/Fuel.svg';
import Maps from '../../assets/sideNav/Maps.svg';
import MapsGreen from '../../assets/sideNav/selected/Maps.svg';
import Solar from '../../assets/sideNav/Solar.svg';
import SolarGreen from '../../assets/sideNav/selected/Solar.svg';
import StarLink from '../../assets/sideNav/StarLink.svg';
import StarLinkGreen from '../../assets/sideNav/selected/StarLink.svg';
import Reports from '../../assets/sideNav/Reports.svg';
import ReportsGreen from '../../assets/sideNav/selected/Reports.svg';
import General from '../../assets/sideNav/General.svg';
import GeneralGreen from '../../assets/sideNav/selected/General.svg';
import globalDashboard from '../../assets/Dashboard/database-01-stroke-rounded 1.png';
import Sider from 'antd/es/layout/Sider';
import './Dashboard.css';
import { Content, Header } from 'antd/es/layout/layout';
import { matchPath, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootreducer';
import { AppDispatch } from '../../store';
import { clearUserInfo, clearWeatherInfo, setSelectedCustomerName, setSelectedSite, setSelectedTimezone } from '../../redux/slices/customerSlice';
import { JSX } from 'react/jsx-runtime';
import { ALARMS, CAMERAS, CHARGERS, DOCS, FUEL, GENERAL, MAPS, POWER_GENERATION, REPORTS, SOLAR, STARLINK } from '../../utils/constants';
import { fetchLiveDataAcrossSitesForSourceIds } from '../../redux/services/chargerSevice';
import { fetchWeatherData, logoutUser } from '../../redux/services/customerService';
import { initiateLogin, truncateByCharacterCount } from '../../utils/common';
import { fetchBPLiveData, fetchGeneratorList } from '../../redux/services/powerService';
import { clearPowerState, setSelectedFuelId, setSelectedFuelSourceId, setSelectedGeneratorId, setSelectedSourceId } from '../../redux/slices/powerSlice';
import { clearChargerData } from '../../redux/slices/chargerSlice';
import { clearDashboardData, clearGeneralData } from '../../redux/slices/dashboardSlice';
import { MenuProps } from 'antd/lib';
import { fetchBPLiveDataForCustomerForOneSite, fetchdetailedSummaryChargerForSite } from '../../redux/services/dashboardService';
import dayjs from 'dayjs';


const Dashboard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 880);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [noSiteFound, setNoSiteFound] = useState<boolean>(false);
    const { permittedCustomers,
        permittedCustomersLoading,
        customerSites,
        selectedCustomerName,
        selectedSite,
        customerSitesLoading,
        userInfo } = useSelector((state: RootState) => state.userInfo);


    const handleResize = () => {
        setIsMobile(window.innerWidth < 880);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { detailedChargerDataForSite, loadingDetailedChargerDataForSite, chargersId } = useSelector((state: RootState) => state.dashboard);

    const { generatorList, selectedFuelId, fuelGenList, loading, selectedGeneratorId } = useSelector((state: RootState) => state.power);

    const presentComponents = customerSites.find((site) => site.name === selectedSite)?.preferences?.present_components;

    useEffect(() => {
        const pollApis = () => {
            // Conditionally dispatch based on presence in presentComponents
            if (presentComponents?.includes(CHARGERS)) {
                dispatch(
                    fetchdetailedSummaryChargerForSite({
                        selectedCustomerName,
                        fromDate: dayjs().subtract(1, 'day').toISOString(), // Serialized date
                        toDate: dayjs().toISOString(), // Serialized date
                        site_id: customerSites.find((x) => x.name === selectedSite)?.id, // Use `find` for cleaner code
                    })
                );
            }

            if (presentComponents?.includes(POWER_GENERATION) || presentComponents?.includes(FUEL) || presentComponents?.includes(GENERAL)) {
                selectedCustomerName === 'bchydro' && dispatch(fetchLiveDataAcrossSitesForSourceIds({ selectedSite, customerSites, selectedCustomerName, source_id: 'BCH-Fuel' }))
                dispatch(fetchGeneratorList({ selectedCustomerName, avail_sites: customerSites, selectedSite }));
                dispatch(fetchBPLiveDataForCustomerForOneSite({ selectedCustomerName, site_id: customerSites.filter(site => site.name === selectedSite)[0].id }))
            }
        };

        pollApis(); // Call immediately on mount
        const interval = setInterval(pollApis, 300000); //5min

        // Cleanup the interval when component unmounts
        return () => clearInterval(interval);
    }, [selectedSite]);

    useEffect(() => {
        const logUrlOnBack = () => {
        };

        // Add event listener for the popstate event
        window.addEventListener("popstate", logUrlOnBack);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("popstate", logUrlOnBack);
        };
    }, []);

    useEffect(() => {
        if (selectedSite && selectedCustomerName) {
            document.title = (permittedCustomers && permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name ?
                permittedCustomers.filter(item => item.customer === selectedCustomerName)[0].name : selectedCustomerName) + ' - ' + selectedSite + ' - ' + 'e-Boost' || 'e-BoostRealM';
        }
    }, [selectedSite, selectedCustomerName]);


    const handleSiderClose = () => {
        setCollapsed(!collapsed);
    };

    const protocol: any = process.env.REACT_APP_PROTOCOL;
    const url: any = process.env.REACT_APP_API_URL;

    const logo = collapsed ? LogoSmall : LogoDark;

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const isDisabled = permittedCustomers.length === 1 || permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading);

    const location = useLocation();

    const { site, chargerId: chargerIdFromURL, powerId: powerIdFromURL, fuelId }: any = useParams();

    useEffect(() => {
        if (site !== selectedSite) {
            const siteExists = customerSites.find((data) => data.name === site);
            if (siteExists)
                dispatch(setSelectedSite(siteExists.name))
        }
    }, [site])

    // Extract the current last segment of the path
    const currentPathSegments = location.pathname.split('/');
    let lastSegment: any = currentPathSegments[currentPathSegments.length - 1];

    if (chargerIdFromURL) {
        lastSegment = CHARGERS
    }

    if (powerIdFromURL) {
        lastSegment = POWER_GENERATION
    }

    if (fuelId) {
        lastSegment = FUEL
    }

    const handleSelectCustomerChange = (value: string) => {
        setNoSiteFound(false);
        dispatch(setSelectedCustomerName(value))
        dispatch(setSelectedTimezone(permittedCustomers.find(data => data.customer === value)?.preferences?.timezone || 'US/Pacific'));
        dispatch(clearUserInfo());
        dispatch(clearPowerState());
        dispatch(clearWeatherInfo());
        dispatch(clearDashboardData());
        dispatch(clearChargerData());
        navigate(`/${value}`);
    }

    const prevLat = useRef<number | null>(null);
    const prevLon = useRef<number | null>(null);


    const siteLocation: any = customerSites.find(
        (site) => site.name === selectedSite,
    )?.location;

    useEffect(() => {
        if (siteLocation?.latitude && siteLocation?.longitude && selectedCustomerName && location.pathname.includes(GENERAL)) {
            const { latitude, longitude } = siteLocation;

            if (latitude !== prevLat.current || longitude !== prevLon.current) {
                prevLat.current = latitude;
                prevLon.current = longitude;

                // Dispatch fetchWeatherData only if the coordinates have changed
                dispatch(fetchWeatherData({ lat: latitude, lon: longitude, selectedCustomerName }));
            }
        }
    }, [siteLocation, location]);

    const handleSelectSiteChange = (value: string) => {
        dispatch(clearChargerData());
        dispatch(clearPowerState());
        dispatch(clearWeatherInfo());
        dispatch(clearWeatherInfo());
        dispatch(clearGeneralData());
        dispatch(setSelectedSite(value));
        const selectedSiteObj = customerSites.find((data) => data.name === value)
        if (selectedSiteObj) {
            const present_components = selectedSiteObj?.preferences?.present_components;
            if (present_components.includes(lastSegment) && ![POWER_GENERATION, FUEL].includes(lastSegment)) {
                navigate(`/${selectedCustomerName}/${value}/${lastSegment}`);
            }
            else if (present_components.includes(lastSegment) && [POWER_GENERATION, FUEL].includes(lastSegment)) {
                // if (selectedCustomerName === 'bchydro')
                //     navigate(`/${selectedCustomerName}/${value}/${GENERAL}`)
            }
            else {
                navigate(`/${selectedCustomerName}/${value}/${GENERAL}`);
            }
        }
    }

    const isPowerRoute = matchPath({ path: `/:customer/:site/${POWER_GENERATION}`, end: true }, location.pathname);
    const isSpecificPowerRoute = matchPath({ path: `/:customer/:site/${POWER_GENERATION}/:powerId`, end: true }, location.pathname);

    useEffect(() => {
        if (generatorList && loading === false && generatorList.find(generator => generator.equipment_id === selectedGeneratorId)) {
            if (selectedGeneratorId && (isPowerRoute || (isSpecificPowerRoute && selectedGeneratorId === Number(powerIdFromURL)))) {
                // Call the action immediately on first load
                dispatch(fetchBPLiveData({
                    selectedCustomerName,
                    avail_sites: customerSites,
                    selectedGeneratorId,
                    selectedSite,
                }));
            }
        }
    }, [generatorList, selectedGeneratorId, powerIdFromURL])

    // useEffect(() => {
    //     const isChargersRoute = matchPath({ path: `/:customer/:site/${CHARGERS}`, end: true }, location.pathname);
    //     const isSpecificChargerRoute = matchPath({ path: `/:customer/:site/${CHARGERS}/:chargerId`, end: true }, location.pathname);

    //     if (chargersId && (isChargersRoute || isSpecificChargerRoute)) {
    //         // Call the action immediately for the first time
    //         dispatch(fetchAllChargersCurrentStatus({ chargersId, selectedCustomerName }));
    //     }
    // }, [chargersId])

    const getSelectedKey = (path: string) => {
        if (path.toLowerCase().includes(POWER_GENERATION.toLowerCase())) {
            return POWER_GENERATION; // Set POWER_GENERATION as selected
        } else if (path.toLowerCase().includes(CHARGERS.toLowerCase())) {
            return CHARGERS; // Set POWER_GENERATION as selected
        } else if (path.toLowerCase().includes(FUEL.toLowerCase())) {
            return FUEL; // Set FUEL as selected
        } else if (path.toLowerCase().includes(CHARGERS.toLowerCase())) {
            return CHARGERS; // Set FUEL as selected
        } else if (path.toLowerCase().includes(MAPS.toLowerCase())) {
            return MAPS; // Set MAPS as selected
        } else if (path.toLowerCase().includes(REPORTS.toLowerCase())) {
            return REPORTS; // Set REPORTS as selected
        } else if (path.toLowerCase().includes(CAMERAS.toLowerCase())) {
            return CAMERAS; // Set CAMERAS as selected
        } else if (path.toLowerCase().includes(ALARMS.toLowerCase())) {
            return ALARMS; // Set ALARMS as selected
        } else if (path.toLowerCase().includes(SOLAR.toLowerCase())) {
            return SOLAR; // Set SOLAR as selected
        }
        //  else if (path.toLowerCase().includes(INTRODUCTION.toLowerCase())) {
        //     return INTRODUCTION; // Set INTRODUCTION as selected
        // }
        else if (path.toLowerCase().includes(GENERAL.toLowerCase())) {
            return GENERAL; // Set GENERAL as selected
        } else if (path.toLowerCase().includes(STARLINK.toLowerCase())) {
            return STARLINK;
        } else if (path.toLowerCase().includes(DOCS.toLowerCase())) {
            return DOCS;
        }
        return ''; // Return an empty string if no match
    };

    // Determine which menu item should be selected based on the current route
    const [selectedKey, setSelectedKey] = useState<string>('');

    useEffect(() => {
        const selectedKey = getSelectedKey(location.pathname);
        setSelectedKey(selectedKey);
    }, [location.pathname])


    const getItem = (label: string, key: string, icon: JSX.Element | undefined, children: null, onClick: any, disabled?: boolean, onTitleClick?: any) => {
        return {
            label,
            key,
            icon,
            children,
            onClick,
            disabled,
            onTitleClick
        };
    };

    const navigate = useNavigate();

    const initialItems = [
        getItem('DASHBOARD', 'Dashboard', <img src={globalDashboard} className='colorChangeWhenSelected' />, null, () => navigate(`/${selectedCustomerName}`)),
        getItem('GENERAL', 'General', <img src={selectedKey === GENERAL ? GeneralGreen : General} alt="general" className='colorChangeWhenSelected' />, null, () => navigate(GENERAL)),
    ];

    const [menuItems, setMenuItems] = useState(initialItems);

    useEffect(() => {

        // Start with the base items array
        let updatedItems = [...initialItems];

        // Conditionally remove "Introduction" based on userInfo.show_intro
        if (userInfo && userInfo.show_intro === false) {
            updatedItems = updatedItems.filter(item => item.key !== 'Introduction');
        }

        // Add items based on presentComponents
        presentComponents?.forEach((item) => {
            if (item === CHARGERS) {
                updatedItems.push({
                    label: 'CHARGERS',
                    key: 'Chargers',
                    icon: <img src={selectedKey === CHARGERS ? ChargerGreen : Charger} alt="charger" className='colorChangeWhenSelected' />,
                    children: chargerMenuItems,
                    onClick: handleChargerMenuItemClick,
                    disabled: false,
                    onTitleClick: () => handleChargerClick(item),
                });
            } else if (item === POWER_GENERATION) {
                updatedItems.push({
                    label: POWER_GENERATION.toLocaleUpperCase(),
                    key: POWER_GENERATION,
                    icon: <img src={selectedKey === POWER_GENERATION ? PowerGreen : PowerSVG} alt="power" className='colorChangeWhenSelected' />,
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: loading,
                    onTitleClick: () => handleMenuItemClick(item),
                });
            } else if (item === FUEL) {
                updatedItems.push({
                    label: FUEL.toLocaleUpperCase(),
                    key: FUEL,
                    icon: <img src={selectedKey === FUEL ? FuelGreen : Fuel} alt="fuel" className='colorChangeWhenSelected' />,
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: loading,
                    onTitleClick: () => handleMenuItemClick(item),
                });
            } else {
                updatedItems.push({
                    label: item.toLocaleUpperCase(),
                    key: item,
                    icon: getIconForPresentItem(item),
                    children: null,
                    onClick: () => handleMenuItemClick(item),
                    disabled: undefined,
                    onTitleClick: undefined,
                });
            }
        });

        // Update the menuItems state
        setMenuItems(updatedItems);

    }, [userInfo, presentComponents, loading, detailedChargerDataForSite, selectedKey]); // userInfo as dependency

    const handleChargerClick = (item: any) => {
        navigate(item);
    }

    let chargerMenuItems: any = [];

    chargerMenuItems = detailedChargerDataForSite.all_connected_chargers.map(data => getItem(data.charger_title, data.id + '', undefined, null, null, loadingDetailedChargerDataForSite)); // Extract names from chargerData   

    const handleChargerMenuItemClick = (e: any) => {
        navigate(`/${selectedCustomerName}/${selectedSite}/${CHARGERS}/${e.key}`);
    };

    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Returns a JSX element representing an icon image based on the provided item key.
     * The icon image will have a different source depending on whether the item key
     * matches the selectedKey, indicating it is currently selected.
     *
     * @param item - A key representing the type of component for which the icon is needed.
     * @returns A JSX element containing an <img> tag with a source and alt text
     *          corresponding to the given item key.
     */
    /******  1c2d722d-da1d-4f4a-8465-1535db2bfd38  *******/
    const getIconForPresentItem = (item: any) => {
        if (item === POWER_GENERATION) {
            return <img src={selectedKey === POWER_GENERATION ? PowerGreen : PowerSVG} alt="power" className='colorChangeWhenSelected' />;
        } else if (item === FUEL) {
            return <img src={selectedKey === FUEL ? FuelGreen : Fuel} alt="fuel" className='colorChangeWhenSelected' />;
        } else if (item === SOLAR) {
            return <img src={selectedKey === SOLAR ? SolarGreen : Solar} alt="solar" className='colorChangeWhenSelected' />;
        } else if (item === STARLINK) {
            return <img src={selectedKey === STARLINK ? StarLinkGreen : StarLink} alt="starlink" className='colorChangeWhenSelected' />;
        } else if (item === REPORTS) {
            return <img src={selectedKey === REPORTS ? ReportsGreen : Reports} alt="report" className='colorChangeWhenSelected' />;
        } else if (item === CAMERAS) {
            return <img src={selectedKey === CAMERAS ? CamerasGreen : Cameras} alt="camera" className='colorChangeWhenSelected' />;
        } else if (item === ALARMS) {
            return <img src={selectedKey === ALARMS ? AlarmsGreen : Alarms} alt="alarm" className='colorChangeWhenSelected' />;
        } else if (item === MAPS) {
            return <img src={selectedKey === MAPS ? MapsGreen : Maps} alt="alarm" className='colorChangeWhenSelected' />;
        } else if (item === DOCS) {
            return <img src={selectedKey === DOCS ? DocsGreen : Docs} className='colorChangeWhenSelected' />;
        }
    }

    const handleMenuItemClick = (item: string) => {
        if (item === SOLAR) {
            navigate(item);
        } else if (item === REPORTS) {
            navigate(item);
        } else if (item === CAMERAS) {
            navigate(item);
        } else if (item === ALARMS) {
            navigate(item);
        } else if (item === MAPS) {
            navigate(item);
        }
        else if (item === DOCS) {
            navigate(item);
        }
        else if (item === STARLINK) {
            navigate(item);
        }
        else
            if (item === POWER_GENERATION) {
                if (generatorList && generatorList.length > 0 && !loading) {
                    // dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}/${generatorList[0].equipment_id}`);
                }
                else if (generatorList && generatorList.length === 0 && !loading) {
                    dispatch(setSelectedGeneratorId(null));
                    dispatch(setSelectedSourceId(''));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${POWER_GENERATION}`);
                }
                else {
                    navigate(item)
                }
            }
            else if (item === FUEL) {
                if (fuelGenList && fuelGenList.length > 0 && !loading) {
                    // dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}/${fuelGenList[0].equipment_id}`);
                }
                else if (fuelGenList && fuelGenList.length === 0 && !loading) {
                    dispatch(setSelectedFuelId(null));
                    dispatch(setSelectedFuelSourceId(''));
                    navigate(`/${selectedCustomerName}/${selectedSite}/${FUEL}`);
                }
                else {
                    navigate(item)
                }
            }
    }

    useEffect(() => {
        if (generatorList && generatorList.length > 0 && !loading) {
            !selectedGeneratorId && dispatch(setSelectedGeneratorId(generatorList[0].equipment_id));
        }
    }, [generatorList, loading]);

    useEffect(() => {
        if (fuelGenList && fuelGenList.length > 0 && !loading) {
            !selectedFuelId && dispatch(setSelectedFuelId(fuelGenList[0].equipment_id));
            !selectedFuelId && dispatch(setSelectedFuelSourceId(fuelGenList[0].source_id));
        }
    }, [fuelGenList, loading]);

    const VersionItem = () => (
        <>
            <div>
                Ver 2.0 API 2.45
            </div>
        </>
    );

    function capitalizeFirstLetter(str: string) {
        if (!str) return str;  // Check for empty string or null/undefined
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const siderContent = (
        <>
            {!isMobile && <div className='imageDiv'>
                <img
                    src={logo}
                    alt="logo"
                    className='actualImage'
                />
            </div>}
            {isMobile && <div className="selectDiv">
                <Select
                    value={selectedCustomerName}
                    loading={permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading)}
                    showSearch
                    options={permittedCustomers.map(item => ({ label: item.name, value: item.customer }))}
                    suffixIcon={<img src={UserLogo} className='h-18' />}
                    onChange={handleSelectCustomerChange}
                    disabled={isDisabled}
                    className={isDisabled ? 'insideSelectMobile select-disabled' : 'insideSelectMobile'}
                />
            </div>}
            {isMobile && <div className="selectDiv">
                <Select
                    disabled={(customerSites.length === 0 && customerSitesLoading) || permittedCustomersLoading}
                    value={selectedSite}
                    showSearch
                    options={customerSites.map(item => ({ label: item.name, value: item.name }))}
                    className='insideSelectMobile'
                    suffixIcon={<EnvironmentOutlined className='h-18 clr-blk' />}
                    onChange={handleSelectSiteChange}
                />
            </div>
            }
            <Skeleton active={true} title={false} className='margin-5' loading={(customerSites.length === 0 && customerSitesLoading)} paragraph={{ rows: 5 }} >
                <Menu
                    theme={"dark"}
                    mode="inline"
                    defaultSelectedKeys={[selectedCustomerName, selectedSite, capitalizeFirstLetter(lastSegment)]}
                    selectedKeys={[selectedCustomerName, selectedSite, chargerIdFromURL, capitalizeFirstLetter(lastSegment), selectedKey]}
                    items={menuItems}
                />
            </Skeleton>
            <div className='versionDiv'>
                <VersionItem />
            </div>
        </>
    );



    const signOut = () => {

        dispatch(logoutUser()).then(() => {
            // Redirect to login page or initiate login
            initiateLogin();
        });
    };

    const items: MenuProps['items'] = [
        {
            key: 'profile',
            label: (
                <span>
                    <SolutionOutlined /> View Profile
                </span>
            ),
        },
        // {
        //     key: 'Introduction',
        //     onClick: handleIntroductionClick,
        //     label: (
        //         <span>
        //             <img src={IntroLogo} alt="introduction" className='h-13' /> Introduction
        //         </span>
        //     ),
        // },
        {
            key: 'logout',
            onClick: signOut,
            label: (
                <span>
                    <PoweroffOutlined /> Log Out
                </span>
            ),
        },
    ];

    const siderStyle: React.CSSProperties = {
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        // display: 'table'
    };

    const siderStyleNoTable: React.CSSProperties = {
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        display: 'flex',
        flexDirection: 'column'
    };

    return (
        <div className='dashboard'>
            <Layout style={{ marginInlineStart: isMobile ? 0 : collapsed ? 80 : 200, height: 'inherit' }}>
                {isMobile ? (
                    <>
                        <Drawer className='clrDrawer'
                            title={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        type="text"
                                        icon={collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                                        onClick={() => handleSiderClose()}
                                        className='mr-10 text-base'
                                    />
                                    <div>Menu</div>
                                </div>
                                <div className='imageDiv'>
                                    <img
                                        src={logo}
                                        alt="logo"
                                        className='actualImage'
                                    />
                                </div>
                            </div>}
                            placement="left"
                            closable={false}
                            onClose={() => setCollapsed(!collapsed)}
                            visible={collapsed}
                        >
                            {siderContent}
                        </Drawer>
                    </>
                ) : (
                    <Sider trigger={null} collapsible collapsed={collapsed} theme={isMobile ? "light" : "dark"} style={!(collapsed || isMobile) ? siderStyle : siderStyleNoTable}>
                        {siderContent}
                    </Sider>

                )}



                <Layout>
                    <Header
                        className="header"
                        style={{
                            position: 'sticky',
                            top: 0,
                            zIndex: 1,
                            width: '100%',
                            height: '64px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            background: colorBgContainer,
                            padding: '0 16px',
                            boxSizing: 'border-box',
                        }}
                    >
                        <div className="collapseDiv" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Button
                                type="text"
                                icon={
                                    collapsed
                                        ? isMobile
                                            ? <MenuFoldOutlined />
                                            : <MenuUnfoldOutlined />
                                        : isMobile
                                            ? <MenuUnfoldOutlined />
                                            : <MenuFoldOutlined />
                                }
                                onClick={handleSiderClose}
                                className="mr-10 text-base"
                            />
                            {!isMobile && (
                                <Select
                                    value={selectedCustomerName}
                                    loading={permittedCustomersLoading || (customerSites.length === 0 && customerSitesLoading)}
                                    showSearch
                                    options={permittedCustomers.map((item) => ({ label: item.name, value: item.customer }))}
                                    suffixIcon={<UserOutlined style={{ fontSize: '16px' }} className="clr-blk" />}
                                    onChange={handleSelectCustomerChange}
                                    disabled={isDisabled}
                                    size="large"
                                    className={isDisabled ? 'insideSelect select-disabled' : 'insideSelect'}
                                    style={{ minWidth: '180px', flexShrink: 0 }}
                                />
                            )}
                            {!isMobile && (
                                <Select
                                    disabled={(customerSites.length === 0 && customerSitesLoading) || permittedCustomersLoading}
                                    value={selectedSite}
                                    showSearch
                                    size="large"
                                    options={customerSites.map((item) => ({ label: item.name, value: item.name }))}
                                    className="insideSelect"
                                    suffixIcon={<EnvironmentOutlined style={{ fontSize: '16px' }} className="h-18 clr-blk" />}
                                    onChange={handleSelectSiteChange}
                                    style={{ minWidth: '180px', flexShrink: 0 }}
                                />
                            )}
                        </div>
                        <div className="collapseDiv" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            <Button
                                type="text"
                                icon={<BellOutlined className="text-base" />}
                                className="mr-10 text-base"
                            />
                            <div className="pointer" style={{ display: 'flex', alignItems: 'center', gap: '8px', maxWidth: '200px', overflow: 'hidden' }}>
                                <Dropdown menu={{ items }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', overflow: 'hidden' }}>
                                        <Avatar
                                            size="small"
                                            icon={<UserOutlined />}
                                            className="avatarAdditional"
                                        >
                                            {userInfo?.first_name && userInfo.first_name.charAt(0)}
                                        </Avatar>
                                        <span
                                            title={userInfo?.first_name}
                                            className="truncate-text"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {truncateByCharacterCount(`${userInfo?.first_name}`, 7)}
                                        </span>
                                    </div>
                                </Dropdown>
                            </div>
                        </div>
                    </Header>
                    <Content
                        className="content"
                    >
                        {(customerSites.length === 0 && customerSitesLoading) && !permittedCustomersLoading ? <Spin size="large">
                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="loadingCol">
                                    <Typography.Title level={2} type="secondary">Loading. Fetching your site details...!</Typography.Title>
                                </Col>
                            </Row>
                        </Spin> : <Outlet />}
                    </Content>
                    {/* <Footer style={{ textAlign: 'center' }}>footer</Footer> */}
                </Layout>

            </Layout>
        </div >
    )
}

export default Dashboard;