import axios from "axios";
const protocol: any = process.env.REACT_APP_PROTOCOL;
const url: any = process.env.REACT_APP_API_URL;
const baseUrl: any = protocol + url;
const authUrl: any = process.env.REACT_APP_AUTH_URL;

export const clearCookie = () => {
    localStorage.removeItem('user');
    axios
        .post(`${baseUrl}microapi/logout-cleanup/`, {}, {
            withCredentials: true,
        })
        .then((response) => {
            // Handle the response as needed
            // Redirect the user to the OAuth2 authorization URL
            window.location.href = authUrl;


        })
        .catch((error) => {
            console.log(error);
        });
}
export function initiateLogin(): void {

    const authUrl: string | undefined = process.env.REACT_APP_AUTH_URL;

    // Redirect the user to the OAuth2 authorization URL if it's defined
    if (authUrl) {
        window.location.href = authUrl;
    } else {
        console.error("Authorization URL is not defined.");
    }
}

export const getBatteryStatus = (status: string) => {
    const statusCharging = ["Preparing", "Finishing", "Charging"];
    const statusAvailable = ["Available"];
    const statusFaulted = ["Faulted", "SuspendedEVSE", "SuspendedEV"];

    if (statusCharging.includes(status)) {
        return 'charging'
    }

    if (statusFaulted.includes(status)) {
        return 'faulted'
    }

    if (statusAvailable.includes(status)) {
        return 'available'
    }
    else
        return status
}

export const PAGESIZE = 8;

export const timezoneAbbreviations: any = {
    '+00:00': 'GMT',
    '-01:00': 'CVT', // Cape Verde Time
    '-02:00': 'ART', // Argentina Time
    '-03:00': 'BRT', // Brasília Time
    '-03:30': 'NST', // Newfoundland Standard Time
    '-04:00': 'AST', // Atlantic Standard Time
    '-05:00': 'EST', // Eastern Standard Time
    '-06:00': 'CST', // Central Standard Time
    '-07:00': 'MST', // Mountain Standard Time
    '-08:00': 'PST', // Pacific Standard Time
    '-09:00': 'AKST', // Alaska Standard Time
    '-10:00': 'HST', // Hawaii-Aleutian Standard Time
    '-11:00': 'SST', // Samoa Standard Time
    '-12:00': 'BIT', // Baker Island Time
    '+01:00': 'CET', // Central European Time
    '+02:00': 'EET', // Eastern European Time
    '+03:00': 'MSK', // Moscow Time
    '+03:30': 'IRST', // Iran Standard Time
    '+04:00': 'GET', // Georgia Time
    '+04:30': 'AFT', // Afghanistan Time
    '+05:00': 'PKT', // Pakistan Standard Time
    '+05:30': 'IST', // Indian Standard Time
    '+05:45': 'NPT', // Nepal Time
    '+06:00': 'BST', // Bangladesh Standard Time
    '+06:30': 'CCT', // Cocos Islands Time
    '+07:00': 'WIB', // Western Indonesian Time
    '+08:00': 'CST', // China Standard Time
    '+09:00': 'JST', // Japan Standard Time
    '+09:30': 'ACST', // Australian Central Standard Time
    '+10:00': 'AEST', // Australian Eastern Standard Time
    '+11:00': 'SBT', // Solomon Islands Time
    '+12:00': 'FJT', // Fiji Time
    '+13:00': 'PHOT', // Phoenix Island Time
    '+14:00': 'LINT', // Line Islands Time
};

export const getTimezoneAbbreviation = () => {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetSign = offsetMinutes > 0 ? '-' : '+';
    const offsetString = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(Math.abs(offsetMinutes) % 60).padStart(2, '0')}`;
    return timezoneAbbreviations[offsetString] || `GMT${offsetString}`;
};

export function truncateByCharacterCount(text: string, count: number) {
    if (text.length > count) {
        return text.slice(0, count) + '...';
    }
    return text; // Return original text if it's within the limit
}